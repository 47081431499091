"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getQueryParam = (param, url) => {
    const regex = new RegExp(`\\b(${param}=)\\b`);
    const match = url.match(regex);
    if (!match) {
        return null;
    }
    const hasMoreValues = url.indexOf('&');
    const initial = match.index + match[0].length;
    if (hasMoreValues >= initial) {
        return url.substring(initial, hasMoreValues);
    }
    const hasAnchor = url.lastIndexOf('#');
    return hasAnchor >= initial ? url.substring(initial, hasAnchor) : url.substring(initial);
};
exports.default = getQueryParam;
