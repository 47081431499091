"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const compareDates = (a, b) => {
    const aDate = new Date(a);
    const bDate = new Date(b);
    if (aDate < bDate)
        return -1;
    if (aDate > bDate)
        return 1;
    return 0;
};
exports.default = compareDates;
